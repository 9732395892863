import { useState, useEffect, useRef } from 'react';

export function useWindowBreakPoint() {
  const isClient = typeof window === 'object';
  const breakpoints = {
    xs: { min: 0, max: 576 },
    sm: { min: 577, max: 767 },
    md: { min: 768, max: 991 },
    lg: { min: 992, max: 1199 },
    xl: { min: 1200, max: 1599 },
    xxl: { min: 1600, max: Infinity },
  };
  const [breakPoint, setBreakPoint] = useState('xl');
  const findBreakPoint = (w) => {
    return Object.keys(breakpoints).find((br) => w >= breakpoints[br].min && w <= breakpoints[br].max);
  };

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setBreakPoint(findBreakPoint(window.innerWidth));
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakPoint;
}

export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export default {
  useWindowBreakPoint,
  useDidUpdateEffect,
  usePrevious,
};
