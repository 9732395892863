import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';

import { smoothScroll } from 'helpers/view';
import { loadMisc } from 'actions/app';

import Banner from '../../components/Banner';
import RecommendedReleases from './RecommendedReleases';
import PromoReleases from './PromoReleases';
import c from './Home.less';

const HomeContainer = () => {
  const dispatch = useDispatch();
  const app = useSelector((store) => store.app);

  useEffect(() => {
    if (app.renderedComponent !== 'Home') {
      dispatch(loadMisc());
    }
    smoothScroll();
  }, []);

  return (
    <div className={c.container}>
      <Helmet defer={false}>
        <title>Mp3Caprice - High Quality Music Downloads</title>
        <meta
          name="description"
          content="Mp3Caprice offers a big variety of mp3 music in different styles and genres. Discover, buy and download high quality tunes for reasonable prices!"
        />
        <meta property="mc:dp" content="/homepage" />
      </Helmet>
      <PromoReleases />
      <Banner />
      <RecommendedReleases />
    </div>
  );
};

export default HomeContainer;
