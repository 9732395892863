import { createSelector } from 'reselect';
import moment from 'moment';
import {
  propOr,
  compose,
  sort,
  keys,
  path,
  head,
} from 'ramda';

export const seriesReleaseList = createSelector(
  [
    (state) => state.releases.bySeries.ids,
    (state) => state.releases.bySeries.total,
    (state, props) => Number(props.match.params.id),
    (state) => state.releases.list.loading,
  ],
  (ids, total, seriesId, loading) => ({
    ids: propOr([], seriesId, ids),
    total: propOr(0, seriesId, total),
    loading,
  })
);

export const topLevelSeriesSelector = createSelector(
  [
    (state) => state.series,
  ],
  (series) => ({
    ...series,
    data: Object.keys(series.data).reduce((acc, seriesId) => {
      if (!series.data[seriesId].parentId) {
        acc[seriesId] = series.data[seriesId];
      }

      return acc;
    }, {}),
  })
);

export const releasesByGenreList = createSelector(
  [
    (state) => state.releases.byGenre.ids,
    (state) => state.releases.byGenre.total,
    (state, props) => Number(props.match.params.id),
    (state) => state.releases.list.loading,
  ],
  (ids, total, genreId, loading) => ({
    ids: propOr([], genreId, ids),
    total: propOr(0, genreId, total),
    loading,
  })
);

export const artistReleaseIdList = createSelector(
  [
    (state) => state.releases.list.data,
    (state, props) => props.match.params.id,
  ],
  (releases, artistId) => Object.keys(releases).filter((id) => releases[id].artist === Number(artistId))
);

export const artistReleaseList = createSelector(
  [
    (state) => state.releases.moreByArtist,
    (state, props) => path([props.match.params.id, 'artist'], state.releases.list.data),
  ],
  (releases, artistId) => ({
    total: releases.total[artistId] || 0,
    ids: releases.ids[artistId] || [],
  })
);

export const trackIdByArtistList = createSelector(
  [
    (state) => state.tracks.list,
    (state, props) => props.match.params.id,
  ],
  (tracks, artistId) => Object.keys(tracks).filter((id) => tracks[id].artist === Number(artistId))
);

export const trackListByArtist = createSelector(
  [
    (state) => state.tracks.byArtist.ids,
    (state) => state.tracks.byArtist.total,
    (state) => state.tracks.list,
    (state, props) => props.match.params.id,
  ],
  (ids, total, trackList, artistId) => ({
    ids: propOr([], artistId, ids),
    items: propOr([], artistId, ids).map((id) => trackList[id]),
    total: propOr(0, artistId, total),
  })
);

export const artistReleaseByTypeList = createSelector(
  [
    (state) => state.releases.byArtist.ids,
    (state) => state.releases.byArtist.total,
    (state, props) => props.match.params.id,
  ],
  (ids, total, artistId) => ({
    ids: propOr([], artistId, ids),
    total: propOr(0, artistId, total),
  })
);

export const releaseByDateList = createSelector(
  [
    (state) => state.releases.byDate.total,
    (state) => state.releases.byDate.ids,
    (state, props) => {
      const { match: { params } } = props;

      if (params.year && params.month && params.day) {
        return moment(`${params.year}-${params.month}-${params.day}`, 'YYYY-M-D').format('YYYY-MM-DD');
      }

      return compose(head, sort((a, b) => new Date(b) - new Date(a)), keys)(state.releases.byDate.ids);
    },
  ],
  (total, ids, date) => ({
    ids: propOr([], date, ids),
    total: propOr(0, date, total),
  })
);

export const reviewsByReleaseList = createSelector(
  [
    (state) => state.reviews.byRelease.ids,
    (state) => state.reviews.byRelease.total,
    (state, props) => props.match.params.id,
  ],
  (reviews, total, releaseId) => ({
    ids: propOr([], releaseId, reviews),
    total: propOr(0, releaseId, total),
  })
);

export const releaseByChartList = createSelector(
  [
    (state) => state.releases.byChart.ids,
    (state) => state.releases.byChart.total,
    (state, props, chartList) => {
      return (chartList.find((c) => c.name === props.match.params.chartName) || {}).key;
    },
  ],
  (ids, total, chartType) => ({
    ids: propOr([], chartType, ids),
    total: propOr(0, chartType, total),
  })
);

export const releaseDatesSelector = createSelector(
  [
    (state) => state.releaseDates.dates,
  ],
  (dates) => dates.map((date) => {
    if (typeof date === 'string') return moment(date);
    return date;
  })
);

const byAddedDate = (a, b) => {
  const dateA = new Date(a.addedDate);
  const dateB = new Date(b.addedDate);
  return dateB - dateA;
};

export const notificationsSelector = createSelector(
  [
    (state) => state.notifications.data,
    (state) => state.notifications.ids,
  ],
  (entities, ids) => ids.map((id) => entities[id]).sort(byAddedDate)
);

export const releaseGenresSelector = createSelector(
  [
    (state, id) => state.releases.list.data[id] || {},
    (state) => state.genres.data,
  ],
  (release, genres) => {
    return release.genres ? release.genres.map((genreId) => genres[genreId]) : [];
  }
);

export const purchasedReleasesSelector = createSelector(
  [
    (state) => state.releases.list,
    (state) => state.releases.purchased.data,
    (state) => state.artistList.data,
  ],
  (releaseList, purchasedIds, artistList) => {
    return purchasedIds
      .map((id) => releaseList.data[id])
      .map((release) => ({ ...release, artist: artistList[release.artist.toString()] }));
  }
);
