import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useWindowBreakPoint } from '../../helpers/hooks';
import c from './Banner.less';

const Banner = () => {
  const { banner } = useSelector((state) => state.app.misc);
  const br = useWindowBreakPoint();
  const getSrcSet = (url) => `${url} 1x, ${url.replace('@1x', '@2x')} 2x`;
  const getImgUrl = () => {
    if (br === 'xs' && banner.mobileUrl) {
      return banner.mobileUrl;
    }
    if (['sm', 'md'].includes(br) && banner.tabletUrl) {
      return banner.tabletUrl;
    }
    if (banner.desktopUrl) {
      return banner.desktopUrl;
    }
    return null;
  };

  const imgUrl = getImgUrl();

  if (!imgUrl) return null;

  let bannerImage = (<img className={c.img} alt="Promo banner" srcSet={getSrcSet(imgUrl)} />);

  if (banner.redirectUrl) {
    bannerImage = (
      <a rel="nofollow" href={banner.redirectUrl}>
        {bannerImage}
      </a>
    );
  }

  return (
    <div className={cx(c.container, c[br])}>
      {bannerImage}
    </div>
  );
};

export default Banner;
