import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { slugify } from 'helpers/view';
import { releaseGenresSelector } from 'selectors';
import c from './PromoReleaseCard.less';

function PromoReleaseCard({
  release, showPrice, history, wide, releaseGenres,
}) {
  const releaseLink = `/release/${slugify(release.name, release.id)}`;
  const artistLink = `/artist/${slugify(release.artist.name, release.artist.id)}`;

  const handleInfoBlockClick = (e) => {
    e.stopPropagation();

    history.push(releaseLink);
  };

  return (
    <div
      className={classNames(c.releaseCard, { [c.wide]: wide })}
      style={{
        backgroundImage: `url(${release.coverUrl}/promo.jpg)`,
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div role="button" tabIndex={0} className={c.info} onClick={handleInfoBlockClick}>
        <Link to={releaseLink}>
          <h4 className={c.releaseName}>{release.name}</h4>
        </Link>
        <Link to={artistLink} className={c.artistName}>{release.artist.name}</Link>

        <div className={c.bottomInfo}>
          <ul className={c.releaseInfo}>
            <li>{release.year}</li>
            <li className={c.sep} />
            <li>{release.tracksCount} Tracks</li>
            <li className={c.sep} />
            <li>{releaseGenres.slice().pop().name}</li>
          </ul>
          {
            showPrice ? (
              <Link to={releaseLink}>
                <span className={c.price}>
                  $<strong>{release.totalPrice.toFixed(2)}</strong>
                </span>
              </Link>
            ) : null
          }
        </div>
      </div>
      <div className={c.coverGradient} />
      <div className={c.coverGradientHover} />
    </div>
  );
}

PromoReleaseCard.propTypes = {
  release: PropTypes.object.isRequired,
  showPrice: PropTypes.bool,
  wide: PropTypes.bool,
  releaseGenres: PropTypes.array.isRequired,
};

PromoReleaseCard.defaultProps = {
  showPrice: true,
  wide: false,
};

const mapStateToProps = (state, props) => ({
  releaseGenres: releaseGenresSelector(state, props.release.id),
});

export default withRouter(connect(mapStateToProps)(PromoReleaseCard));
