import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { memo, useEffect, useMemo } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';

import { getPromo } from 'actions/release';
import PromoReleaseCard from 'components/PromoReleaseCard';

import c from './PromoReleases.less';

const PromoReleases = () => {
  const dispatch = useDispatch();
  const {
    app,
    artistList,
    promoReleaseIdList,
    releaseList,
  } = useSelector((store) => ({
    app: store.app,
    artistList: store.artistList,
    promoReleaseIdList: store.releases.promoReleaseIdList,
    releaseList: store.releases.list,
  }), shallowEqual);

  useEffect(() => {
    if (app.renderedComponent === 'Home') return;

    dispatch(getPromo());
  }, []);

  const promoReleases = useMemo(() => promoReleaseIdList
    .map((id) => releaseList.data[id])
    .filter((release) => release)
    .map((release) => ({
      ...release,
      artist: artistList.data[release.artist.toString()],
    })), [promoReleaseIdList]);

  return (
    <Row
      gutter={{
        xs: 8, sm: 16, md: 20, lg: 20,
      }}
      className={c.promoContainer}
    >
      {
        promoReleases.map((release, idx) => (
          <Col
            xs={24}
            sm={12}
            {...{
              md: idx === 0 ? 14 : 10,
              lg: idx === 0 ? 14 : 10,
            }}
            className={classNames({
              'hidden-mobile': idx === 0,
            })}
            key={release.id}
          >
            <PromoReleaseCard wide={idx === 0} release={release} />
          </Col>
        ))
      }
    </Row>
  );
};

export default memo(PromoReleases);
